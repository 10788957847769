.main-footer
  padding: em(20px) 0
  background: #303030
  color blanco
  +mq('large')
    padding: em(30px) 0

  +e(logo)
    max-width: 100px
    margin-bottom: em(10px)
    +mq('large')
      min-width: 120px
      margin-bottom: em(40px)

  +e(social)
    color blanco
    font-family: montserrat
    font-weight: bold
    fonty(14, 18, 3.74)
    text-transform: uppercase
    display: flex
    align-items: center
    margin-bottom: em(15px)
    +mq('large')
      margin-bottom: em(20px)
      fonty(18, 22, 7.75)
      margin-right: em(15px)
      margin-left: em(15px)

    .facebook
      width: 15px
      height: 15px
      margin-right: em(10px)
      +mq('large')
        width: 30px
        height: 30px

    .instagram
      margin-right: em(10px)
      width: 15px
      height: 15px
      +mq('large')
        width: 30px
        height: 30px

  +e(info)
    color blanco
    font-family: montserrat
    font-weight: bold
    fonty(14,18,2.16)
    margin-bottom: em(15px)
    +mq('large')
      margin-bottom: em(20px)
      fonty(18, 22,4.48)
      margin-right: em(15px)
      margin-left: em(15px)

  .flexyrow
    +mq('large')
      justify-content: center

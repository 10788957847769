body
  color negro
  font-family lora
  font-size base__font-size
  line-height: base__line-height

h1
  @extend $header-default
  fonty(45,54)
  +mq('large')
    fonty(72,87)

h2
  @extend $header-default
  fonty(35, 42)
  +mq('large')
    fonty(72, 87)

h3
  @extend $header-default

h4
  @extend $header-default

h5
  @extend $header-default

h6
  @extend $header-default

.recetas
  padding: 30px 0
  display: flex
  flex-direction: column
  align-items: center
  flex-wrap: wrap
  +mq('medium')
    flex-direction: row
    flex-wrap: wrap
    align-items: flex-start

  .receta
    max-width: 100%
    flex-basis: auto
    text-align: center
    font-size: 20px
    color negro
    +mq('medium')
      max-width: percentage(1/3)
      flex-basis: percentage(1/3)
      padding: 0 15px

    img, span
      display: block

    span
      margin: 10px auto

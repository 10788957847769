.header-receta
  margin-top: 40px
  padding: 30px 0 10px
  text-align: center
  +mq('large')
    margin-top: 40px
    padding: 50px 0

  h1
    font-family: lora
    font-weight: normal
    font-size: 38px
    +mq('large')
      font-size: 64px
      margin-bottom: 30px

  img
    display: block
    margin: 0 auto

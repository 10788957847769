.contacto
  cf()
  +mq('medium')
    min-height: 100vh

  &__poster
    content: ''
    width: 100%
    height: 400px
    background linear-gradient(rgba(negro, 0.2), rgba(negro, 0.2)) ,url('../img/contacto.jpg') no-repeat center / cover

    +mq('medium')
      float: left
      position: fixed
      top: 0
      width: 50%
      height: 100vh


  &__detalles
    padding: 20px 0
    text-align: center
    +mq('medium')
      float: left
      width: 50%
      margin-left: 50%

    h1, h4, h6
      font-family: lora
      font-weight: normal

    h4
      font-size: 21px
      +mq('large')
        font-size: 35px

    h1
      font-size: 30px
      +mq('medium')
        font-size: 50px
      +mq('large')
        font-size: 72px

    h6
      font-size: 18px
      +mq('large')
        font-size: 25px

    hr
      max-width: 100px
      margin: 10px auto
      +mq('medium')
        max-width: 200px
        margin: 20px auto

  form
    margin-bottom: 20px
    +mq('medium')
      margin-top: 40px
      margin-bottom: 40px

  input, textarea
    display: block
    padding: 15px
    background: #f4f4f4
    border 1px solid #a5a5a5
    text-transform: uppercase
    margin: 10px auto
    width: 100%
    font-family: montserrat
    font-weight: normal
    font-size: 18px
    +mq('medium')
      margin: 40px auto

  input[type='submit']
    background negro
    color: blanco
    max-width: 170px

  .flexyrow
    +mq('medium')
      justify-content: center

  &__social
    color gris
    font-family: montserrat
    font-weight: bold
    fonty(14, 18, 3.74)
    text-transform: uppercase
    display: flex
    align-items: center
    margin-bottom: em(15px)
    +mq('medium')
      margin-bottom: 20px
      margin-right: 15px
      margin-left: 15px
      fonty(16, 18, 3.69)


    .facebook
      width: 15px
      height: 15px
      margin-right: em(10px)
      +mq('medium')
        width: 20px
        height: 20px

    .instagram
      margin-right: em(10px)
      width: 15px
      height: 15px
      +mq('medium')
        width: 20px
        height: 20px

  &__info
    color #4a4a4a
    font-family: montserrat
    font-weight: bold
    fonty(14,18,2.16)
    margin-bottom: em(15px)
    +mq('medium')
      margin-bottom: 0
      margin-right: 15px
      margin-left: 15px

.button
  font-family: montserrat
  font-weight: bold
  font-size: 13px
  text-transform: uppercase
  background: negro
  color: blanco
  padding: 10px
  letter-spacing: 1.95px
  max-width: 250px
  margin: 10px auto
  border 2px solid negro
  text-align: center
  &:hover
    color blanco
    text-decoration: underline


.btn--primary
  @extend .button

.button--light
  @extend .button
  background blanco
  color negro
  &:hover
    color negro

.button--match-shopify
  padding: 4px 8px
  border-width: 1px
  border-style: solid
  border-radius: 3px
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif
  background blanco
  color negro
  font-size: 14px
  font-weight: bold
  max-width: 155px
  margin: 10px auto
  text-transform: uppercase
  &:hover
    color blanco
    background: negro

// No estoy orgulloso de esto jaja Shopify debería avergonzarse
.button--buy
  margin-bottom: -9px
  margin-right: -75px
  +mq('medium')
    margin-right: -55px

#fp-nav
  z-index: 1

#fp-nav ul li a span
  background: transparent
  border 1px solid blanco

#fp-nav ul li a.active span
  background: blanco

#fp-nav.right
  +mq('large')
    right 45px

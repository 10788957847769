.nosotros-parrafo
  background: negro
  color: blanco
  padding: 50px 0
  font-size: 20px
  +mq('large')
    font-size: 25px
    line-height: (40/34)

  p + p
    margin-top: 30px

.overlay
  position: fixed;
  top 0
  left: 0
  width: 100%
  height: 100vh
  background rgba(negro, 0.5)
  z-index: 1
  opacity: 0
  pointer-events: none
  transition: opacity trns-duration
  +is(active)
    opacity 1
    pointer-events: all

.sidemenu
  position: fixed
  top 0
  left: 0
  width: 100%
  height: 100%
  background: lighten(negro, 25%)
  text-align: center
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  z-index: 2
  pointer-events: none
  transform: translateX(-100%)
  transition: transform trns-duration
  +is(active)
    pointer-events: all
    transform: translateX(0)
  +mq('medium')
    width: 50%

  +e(social)
    display: flex
    margin-top: 20px

    .icon
      width: 35px
      height: 35px
      margin: 0 10px


  a
    color: blanco
    text-transform: uppercase
    font-family: montserrat
    font-weight: bold
    font-size: 30px
    &:hover
      color: main-color
    +mq('medium')
      font-size: 35px
    +mq('large')
      font-size: 40px



.burger-toggle
  position: fixed
  top: em(20px)
  left: em(10px)
  width: em(50px)
  height em(50px)
  content ''
  z-index: 5
  display: flex
  align-items: center
  cursor: pointer
  +mq('medium')
    left: em(20px)
  +mq('large')
    top em(40px)

  .burger
    content ''
    width 100%
    height em(3.5px)
    background: gris
    position: relative
    transition: background trns-duration
    &::before, &::after
      position: absolute
      content ''
      width 100%
      height em(4px)
      background: gris
      transition: transform trns-duration cubic-bezier(.5,-.75,.7,2), background trns-duration
    &::before
      transform: translate(0, -12px)
    &::after
      transform: translate(0, 12px)

    +is(active)
      background: transparent
      &::before
        transform: rotate(45deg) translate(0)
        background: blanco
      &::after
        transform: rotate(-45deg) translate(0)
        background: blanco

.producto
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  text-align: center
  +mq('medium')
    flex-direction: row
    text-align: left

  .mitad
    max-width: 100%
    flex-basis: auto
    +mq('medium')
      max-width: 50%
      flex-basis: 50%

  .reverse
    +mq('medium')
      order: -1

  h3
    font-family: lora
    font-weight: bold
    font-size: 30px
    line-height: (55/30)
    letter-spacing: -1.31px
    +mq('large')
      font-size: 52px
      letter-spacing: -2.27px

  p
    font-size: 18px
    line-height: (36/18)
    color #656B6F
    +mq('large')
      font-size: 30px
      margin-bottom: 20px

  a
    display: block
    +mq('medium')
      display: inline-block
      margin: 10px

.row
  cf()

.flexyrow
  display: flex
  flex-direction: column
  flex-wrap: wrap
  align-items: center
  justify-content: center
  text-align: center
  max-width: 100%

  +mq('medium')
    flex-direction: row
    justify-content: space-around

.vertical
  flex-direction: column

.horizontal
  flex-direction: row

// An easy container inspired by Skeleton - http://getskeleton.com/

.container
  position: relative
  width: 100%
  max-width: 1170px
  margin: 0 auto
  padding: 0 20px
  box-sizing: border-box
  cf()

  +mq('medium')
    width: 85%

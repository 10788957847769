.poster
  content ''
  background darken(azul, 20%)
  width: 100%
  height: 360px
  +mq('medium')
    height: 520px
  +mq('large')
    background-attachment: fixed !important
  +m(1)
    background: url('../img/poster1.jpg') no-repeat center / cover
  +m(2)
    background: url('../img/poster2.jpg') no-repeat center / cover
  +m(3)
    background: url('../img/poster3.jpg') no-repeat center / cover
  +m(4)
    background: url('../img/poster4.jpg') no-repeat center / cover

.hide-desktop
  +mq('medium')
    display: none

.producto-info
  padding: 20px 0
  +mq('medium')
    display: flex
    align-items: center
    margin-top: 40px
    padding: 50px 0


  .button
    display: block
    +mq('medium')
      display: inline-block

  .imagen
    display: block
    +mq('medium')
      display: block
      max-width: 40%
      flex-basis: 40%

  .datos
    +mq('medium')
      max-width: 60%
      flex-basis: 60%
      padding: 0 30px

  h3
    font-family: lora
    font-weight: bold
    font-size: 27px
    letter-spacing: -2.27px
    +mq('medium')
      font-size: 52px

  p
    font-size: 18px
    +mq('medium')
      font-size: 23px

  p + p
    margin-top: 20px
    +mq('medium')
      margin-top: 30px

  span
    font-weight: bold

  .caracteristicas
    display: block
    margin-top: 30px

.standard-header
  margin-top: 70px
  padding: 30px 0
  text-align: center
  +mq('large')
    margin-top: 40px
    padding: 70px 0

  h1
    font-family: lora
    font-size: 33px
    margin-bottom: 30px
    +mq('large')
      font-size: 64px

  h2
    font-family: lora
    font-size: 18px
    text-transform: uppercase
    +mq('large')
      font-size: 37px

  hr
    border: 0
    border-top: 2px solid negro
    margin-top: 30px

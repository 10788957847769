.slide
  background darken(azul, 30%)
  color blanco
  min-height: 100vh
  position: relative
  +m(1)
    back('hero1.jpg', rgba(negro, 0.4))
  +m(2)
    back('hero2.jpg', rgba(negro, 0.4))
  +m(3)
    back('hero3.jpg', rgba(negro, 0.4))
  +m(4)
    back('hero4.jpg', rgba(negro, 0.4))

  &.active
    animation: intro 1s

    &::before
      content ''
      position: absolute
      top 0
      width: 100%
      height: 100vh
      animation: flash 1s

  .container
    height: 100%
    position: relative

  .centrado
    align(both)
    text-align: center
    +mq('medium')
      width: 90%

  .title
    @extend $header-default
    fonty(45,54)
    display: inline
    +mq('large')
      fonty(72, 87)

    +m(small)
      display inline
      font-size: 30px
      line-height: 1.2
      +mq('large')
        fonty(57,69)


  .enfasis
    font-family: lora
    font-style: italic
    fonty(45,54)
    +mq('large')
      fonty(72, 87)

    +m(small)
      font-family: lora
      font-style: italic
      font-size: 30px
      line-height: 1.2
      +mq('large')
        fonty(57,69)

// Background Mixin with optional overlay
back($img, $overlay = null, $path = image-path)
  if $overlay is null
    background: url($path $img) no-repeat center / cover
  else
    background: linear-gradient($overlay, $overlay),url($path $img) no-repeat center / cover


// BEM Mixins!
e(bEm)
	&__{bEm}
		{block}

m(beM)
	&--{beM}
		{block}

is(toggable)
	&.is-{toggable}
		{block}

// Easy Media Queries
mq(breakpoint)
	size = breakpoints[breakpoint]
	if size != null
		@media (min-width:size)
			{block}
	else
		error("Desafortunadamente tu breakpoint más reciente no existe 😩 revisa el archivo _breakpoints.styl para saber cuales puedes usar.")


// Google Font Importer
if type-of(font-url--google) is 'string'
	@import url(font-url--google)

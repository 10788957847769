.receta
  padding-bottom: 40px

  .detalles
    display: flex
    align-items: center
    color gris
    padding: 10px 0

    .clock, .user
      width 35px
      height 35px
      margin: 0
      margin-right: 10px
      display: inline-block

    span
      font-size: 14px
      display: inline-block
      margin-right: 10px

  .informacion
    display: flex
    flex-direction: column
    font-size: 20px
    padding: 20px 0
    +mq('medium')
      flex-direction: row

    .mitad
      max-width: 100%
      flex-basis: auto
      +mq('medium')
        max-width: 50%
        flex-basis: 50%

    h2
      font-family: lora
      font-weight: bold
      font-size: 28px
      margin-bottom: 20px

    ul, ol
      margin: 20px 0
      padding: 0 20px

    ul
      list-style-type: disc

    ol
      list-style: decimal
      li
        margin-bottom: 10px

  .socialidad
    display: flex
    justify-content: center
    align-items: center
    +mq('medium')
      justify-content: flex-start

    .download, .facebook
      width: 35px
      height: 35px
      margin: 10px
      color gris

#video-hero
  color blanco
  background: rgba(negro, 0.4)
  min-height: 100vh
  position: relative
  &.active
    animation: intro 1s
    &::before
      content ''
      position: absolute
      top 0
      width: 100%
      height: 100vh
      animation: flash 1s

  .container
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center

  .flexyrow
    width: 100%
    position: relative

  .logo
    @extend $hide-text
    display: inline-block
    background: url('../img/logo.png') no-repeat center / cover
    width: 80px
    height: 133px
    align(horizontal)
    top 0
    +mq('large')
      width (80 * 1.5)px
      height (133 * 1.5)px

  .idioma
    display: inline-block
    text-transform: uppercase
    font-family: montserrat
    font-weight: bold
    fonty(25.16,30)
    padding: em(4px) em(6px)
    border 4px solid blanco
    color blanco
    align-self: flex-start
    margin-left: auto
    margin-top: em(15px)
    &:hover
      background: blanco
      color: rosa
    &:active
      transform: scale(0.95)
      transform-origin: center
    +mq('large')
      fonty(36, 44)


  .title
    margin: auto 0
    text-align: center

  .scroll
    width em(75px)
    height: em(35px)
    margin-bottom: em(90px)
    animation bounce 3.5s infinite
    cursor: pointer

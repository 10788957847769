*, *::before, *::before
  box-sizing: border-box

::selection
  background: main-color
  color: white

img
  max-width: 100%
  height auto

.is-hidden
	position: absolute
	height: 0
	width: 0
	visibility: hidden

// Style for the SVGs
.icon
  display: block
  width: 100%
  fill: currentColor
  stroke currentColor
  margin: 0 auto

/*
 * Odin - Un framework Stylus hecho por: @mpalau
 * Basado en los principios BEM y SMACSS.
 * Version 2.0
 * Copyright 2016
 *
 * Funciona mucho mucho mejor si tienes instalado Axis y Jeet.gs
 * NOTA: Usa Autoprefixer 😜
*/
@import url("https://fonts.googleapis.com/css?family=Montserrat|Lora:400,400italic");
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-weight: inherit;
  font-style: inherit;
  font-family: inherit;
  font-size: 100%;
  vertical-align: baseline;
}
body {
  line-height: 1;
  color: #222;
  background: #fff;
}
ol,
ul {
  list-style: none;
}
table {
  border-collapse: separate;
  border-spacing: 0;
  vertical-align: middle;
}
caption,
th,
td {
  text-align: left;
  font-weight: 400;
  vertical-align: middle;
}
a img {
  border: none;
}
#video-hero .logo {
  text-indent: 101%;
  overflow: hidden;
  white-space: nowrap;
}
h1,
h2,
h3,
h4,
h5,
h6,
.slide .title {
  font-family: 'Lora', serif;
  font-weight: 400;
}
*,
*::before {
  box-sizing: border-box;
}
::selection {
  background: #ec7024;
  color: #fff;
}
img {
  max-width: 100%;
  height: auto;
}
.is-hidden {
  position: absolute;
  height: 0;
  width: 0;
  visibility: hidden;
}
.icon {
  display: block;
  width: 100%;
  fill: currentColor;
  stroke: currentColor;
  margin: 0 auto;
}
body {
  color: #000;
  font-family: 'Lora', serif;
  font-size: 16px;
  line-height: 1.65;
}
h1 {
  font-size: 45px;
  letter-spacing: 0px;
  line-height: 1.2;
}
@media (min-width: 64em) {
  h1 {
    font-size: 72px;
    letter-spacing: 0px;
    line-height: 1.208333333333333;
  }
}
h2 {
  font-size: 35px;
  letter-spacing: 0px;
  line-height: 1.2;
}
@media (min-width: 64em) {
  h2 {
    font-size: 72px;
    letter-spacing: 0px;
    line-height: 1.208333333333333;
  }
}
a {
  text-decoration: none;
  transition: 0.3s;
  color: #ec7024;
}
a:hover {
  color: #ec7024;
}
label {
  display: none;
}
.container {
  position: relative;
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;
  *zoom: 1;
}
.container:before,
.container:after {
  content: '';
  display: table;
}
.container:after {
  clear: both;
}
@media (min-width: 50em) {
  .container {
    width: 85%;
  }
}
.row {
  *zoom: 1;
}
.row:before,
.row:after {
  content: '';
  display: table;
}
.row:after {
  clear: both;
}
.flexyrow {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  max-width: 100%;
}
@media (min-width: 50em) {
  .flexyrow {
    flex-direction: row;
    justify-content: space-around;
  }
}
.vertical {
  flex-direction: column;
}
.horizontal {
  flex-direction: row;
}
#fp-nav {
  z-index: 1;
}
#fp-nav ul li a span {
  background: transparent;
  border: 1px solid #fff;
}
#fp-nav ul li a.active span {
  background: #fff;
}
@media (min-width: 64em) {
  #fp-nav.right {
    right: 45px;
  }
}
@-moz-keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@-webkit-keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@-o-keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@keyframes bounce {
  from, 20%, 53%, 80%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -30px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -15px, 0);
  }
  90% {
    transform: translate3d(0, -4px, 0);
  }
}
@-moz-keyframes intro {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes intro {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes intro {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes intro {
  0% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@-moz-keyframes flash {
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
}
@-webkit-keyframes flash {
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
}
@-o-keyframes flash {
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
}
@keyframes flash {
  0% {
    background: #fff;
  }
  100% {
    background: transparent;
  }
}
.button,
.btn--primary,
.button--light {
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 13px;
  text-transform: uppercase;
  background: #000;
  color: #fff;
  padding: 10px;
  letter-spacing: 1.95px;
  max-width: 250px;
  margin: 10px auto;
  border: 2px solid #000;
  text-align: center;
}
.button:hover,
.btn--primary:hover,
.button--light:hover {
  color: #fff;
  text-decoration: underline;
}
.button--light {
  background: #fff;
  color: #000;
}
.button--light:hover {
  color: #000;
}
.button--match-shopify {
  padding: 4px 8px;
  border-width: 1px;
  border-style: solid;
  border-radius: 3px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background: #fff;
  color: #000;
  font-size: 14px;
  font-weight: 700;
  max-width: 155px;
  margin: 10px auto;
  text-transform: uppercase;
}
.button--match-shopify:hover {
  color: #fff;
  background: #000;
}
.button--buy {
  margin-bottom: -9px;
  margin-right: -75px;
}
@media (min-width: 50em) {
  .button--buy {
    margin-right: -55px;
  }
}
.main-footer {
  padding: 1.25em 0;
  background: #303030;
  color: #fff;
}
@media (min-width: 64em) {
  .main-footer {
    padding: 1.875em 0;
  }
}
.main-footer__logo {
  max-width: 100px;
  margin-bottom: 0.625em;
}
@media (min-width: 64em) {
  .main-footer__logo {
    min-width: 120px;
    margin-bottom: 2.5em;
  }
}
.main-footer__social {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3.74px;
  line-height: 1.285714285714286;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 0.9375em;
}
@media (min-width: 64em) {
  .main-footer__social {
    margin-bottom: 1.25em;
    font-size: 18px;
    letter-spacing: 7.75px;
    line-height: 1.222222222222222;
    margin-right: 0.9375em;
    margin-left: 0.9375em;
  }
}
.main-footer__social .facebook {
  width: 15px;
  height: 15px;
  margin-right: 0.625em;
}
@media (min-width: 64em) {
  .main-footer__social .facebook {
    width: 30px;
    height: 30px;
  }
}
.main-footer__social .instagram {
  margin-right: 0.625em;
  width: 15px;
  height: 15px;
}
@media (min-width: 64em) {
  .main-footer__social .instagram {
    width: 30px;
    height: 30px;
  }
}
.main-footer__info {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2.16px;
  line-height: 1.285714285714286;
  margin-bottom: 0.9375em;
}
@media (min-width: 64em) {
  .main-footer__info {
    margin-bottom: 1.25em;
    font-size: 18px;
    letter-spacing: 4.48px;
    line-height: 1.222222222222222;
    margin-right: 0.9375em;
    margin-left: 0.9375em;
  }
}
@media (min-width: 64em) {
  .main-footer .flexyrow {
    justify-content: center;
  }
}
.standard-header {
  margin-top: 70px;
  padding: 30px 0;
  text-align: center;
}
@media (min-width: 64em) {
  .standard-header {
    margin-top: 40px;
    padding: 70px 0;
  }
}
.standard-header h1 {
  font-family: 'Lora', serif;
  font-size: 33px;
  margin-bottom: 30px;
}
@media (min-width: 64em) {
  .standard-header h1 {
    font-size: 64px;
  }
}
.standard-header h2 {
  font-family: 'Lora', serif;
  font-size: 18px;
  text-transform: uppercase;
}
@media (min-width: 64em) {
  .standard-header h2 {
    font-size: 37px;
  }
}
.standard-header hr {
  border: 0;
  border-top: 2px solid #000;
  margin-top: 30px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0,0,0,0.5);
  z-index: 1;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}
.overlay.is-active {
  opacity: 1;
  pointer-events: all;
}
.sidemenu {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #404040;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 2;
  pointer-events: none;
  transform: translateX(-100%);
  transition: transform 0.3s;
}
.sidemenu.is-active {
  pointer-events: all;
  transform: translateX(0);
}
@media (min-width: 50em) {
  .sidemenu {
    width: 50%;
  }
}
.sidemenu__social {
  display: flex;
  margin-top: 20px;
}
.sidemenu__social .icon {
  width: 35px;
  height: 35px;
  margin: 0 10px;
}
.sidemenu a {
  color: #fff;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 30px;
}
.sidemenu a:hover {
  color: #ec7024;
}
@media (min-width: 50em) {
  .sidemenu a {
    font-size: 35px;
  }
}
@media (min-width: 64em) {
  .sidemenu a {
    font-size: 40px;
  }
}
.burger-toggle {
  position: fixed;
  top: 1.25em;
  left: 0.625em;
  width: 3.125em;
  height: 3.125em;
  content: '';
  z-index: 5;
  display: flex;
  align-items: center;
  cursor: pointer;
}
@media (min-width: 50em) {
  .burger-toggle {
    left: 1.25em;
  }
}
@media (min-width: 64em) {
  .burger-toggle {
    top: 2.5em;
  }
}
.burger-toggle .burger {
  content: '';
  width: 100%;
  height: 0.21875em;
  background: #787878;
  position: relative;
  transition: background 0.3s;
}
.burger-toggle .burger::before,
.burger-toggle .burger::after {
  position: absolute;
  content: '';
  width: 100%;
  height: 0.25em;
  background: #787878;
  transition: transform 0.3s cubic-bezier(0.5, -0.75, 0.7, 2), background 0.3s;
}
.burger-toggle .burger::before {
  transform: translate(0, -12px);
}
.burger-toggle .burger::after {
  transform: translate(0, 12px);
}
.burger-toggle .burger.is-active {
  background: transparent;
}
.burger-toggle .burger.is-active::before {
  transform: rotate(45deg) translate(0);
  background: #fff;
}
.burger-toggle .burger.is-active::after {
  transform: rotate(-45deg) translate(0);
  background: #fff;
}
.slide {
  background: #015c8b;
  color: #fff;
  min-height: 100vh;
  position: relative;
}
.slide--1 {
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url("../img/hero1.jpg") no-repeat center/cover;
}
.slide--2 {
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url("../img/hero2.jpg") no-repeat center/cover;
}
.slide--3 {
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url("../img/hero3.jpg") no-repeat center/cover;
}
.slide--4 {
  background: linear-gradient(rgba(0,0,0,0.4), rgba(0,0,0,0.4)), url("../img/hero4.jpg") no-repeat center/cover;
}
.slide.active {
  animation: intro 1s;
}
.slide.active::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  animation: flash 1s;
}
.slide .container {
  height: 100%;
  position: relative;
}
.slide .centrado {
  position: absolute;
  transform-style: preserve-3d;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
@media (min-width: 50em) {
  .slide .centrado {
    width: 90%;
  }
}
.slide .title {
  font-size: 45px;
  letter-spacing: 0px;
  line-height: 1.2;
  display: inline;
}
@media (min-width: 64em) {
  .slide .title {
    font-size: 72px;
    letter-spacing: 0px;
    line-height: 1.208333333333333;
  }
}
.slide .title--small {
  display: inline;
  font-size: 30px;
  line-height: 1.2;
}
@media (min-width: 64em) {
  .slide .title--small {
    font-size: 57px;
    letter-spacing: 0px;
    line-height: 1.210526315789474;
  }
}
.slide .enfasis {
  font-family: 'Lora', serif;
  font-style: italic;
  font-size: 45px;
  letter-spacing: 0px;
  line-height: 1.2;
}
@media (min-width: 64em) {
  .slide .enfasis {
    font-size: 72px;
    letter-spacing: 0px;
    line-height: 1.208333333333333;
  }
}
.slide .enfasis--small {
  font-family: 'Lora', serif;
  font-style: italic;
  font-size: 30px;
  line-height: 1.2;
}
@media (min-width: 64em) {
  .slide .enfasis--small {
    font-size: 57px;
    letter-spacing: 0px;
    line-height: 1.210526315789474;
  }
}
#video-hero {
  color: #fff;
  background: rgba(0,0,0,0.4);
  min-height: 100vh;
  position: relative;
}
#video-hero.active {
  animation: intro 1s;
}
#video-hero.active::before {
  content: '';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100vh;
  animation: flash 1s;
}
#video-hero .container {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#video-hero .flexyrow {
  width: 100%;
  position: relative;
}
#video-hero .logo {
  display: inline-block;
  background: url("../img/logo.png") no-repeat center/cover;
  width: 80px;
  height: 133px;
  position: absolute;
  transform-style: preserve-3d;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
}
@media (min-width: 64em) {
  #video-hero .logo {
    width: 120px;
    height: 199.5px;
  }
}
#video-hero .idioma {
  display: inline-block;
  text-transform: uppercase;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 25.16px;
  letter-spacing: 0px;
  line-height: 1.192368839427663;
  padding: 0.25em 0.375em;
  border: 4px solid #fff;
  color: #fff;
  align-self: flex-start;
  margin-left: auto;
  margin-top: 0.9375em;
}
#video-hero .idioma:hover {
  background: #fff;
  color: #da0060;
}
#video-hero .idioma:active {
  transform: scale(0.95);
  transform-origin: center;
}
@media (min-width: 64em) {
  #video-hero .idioma {
    font-size: 36px;
    letter-spacing: 0px;
    line-height: 1.222222222222222;
  }
}
#video-hero .title {
  margin: auto 0;
  text-align: center;
}
#video-hero .scroll {
  width: 4.6875em;
  height: 2.1875em;
  margin-bottom: 5.625em;
  animation: bounce 3.5s infinite;
  cursor: pointer;
}
.nosotros-parrafo {
  background: #000;
  color: #fff;
  padding: 50px 0;
  font-size: 20px;
}
@media (min-width: 64em) {
  .nosotros-parrafo {
    font-size: 25px;
    line-height: 1.176470588235294;
  }
}
.nosotros-parrafo p + p {
  margin-top: 30px;
}
.poster {
  content: '';
  background: #016a9f;
  width: 100%;
  height: 360px;
}
@media (min-width: 50em) {
  .poster {
    height: 520px;
  }
}
@media (min-width: 64em) {
  .poster {
    background-attachment: fixed !important;
  }
}
.poster--1 {
  background: url("../img/poster1.jpg") no-repeat center/cover;
}
.poster--2 {
  background: url("../img/poster2.jpg") no-repeat center/cover;
}
.poster--3 {
  background: url("../img/poster3.jpg") no-repeat center/cover;
}
.poster--4 {
  background: url("../img/poster4.jpg") no-repeat center/cover;
}
@media (min-width: 50em) {
  .hide-desktop {
    display: none;
  }
}
.producto-info {
  padding: 20px 0;
}
@media (min-width: 50em) {
  .producto-info {
    display: flex;
    align-items: center;
    margin-top: 40px;
    padding: 50px 0;
  }
}
.producto-info .button {
  display: block;
}
@media (min-width: 50em) {
  .producto-info .button {
    display: inline-block;
  }
}
.producto-info .imagen {
  display: block;
}
@media (min-width: 50em) {
  .producto-info .imagen {
    display: block;
    max-width: 40%;
    flex-basis: 40%;
  }
}
@media (min-width: 50em) {
  .producto-info .datos {
    max-width: 60%;
    flex-basis: 60%;
    padding: 0 30px;
  }
}
.producto-info h3 {
  font-family: 'Lora', serif;
  font-weight: 700;
  font-size: 27px;
  letter-spacing: -2.27px;
}
@media (min-width: 50em) {
  .producto-info h3 {
    font-size: 52px;
  }
}
.producto-info p {
  font-size: 18px;
}
@media (min-width: 50em) {
  .producto-info p {
    font-size: 23px;
  }
}
.producto-info p + p {
  margin-top: 20px;
}
@media (min-width: 50em) {
  .producto-info p + p {
    margin-top: 30px;
  }
}
.producto-info span {
  font-weight: 700;
}
.producto-info .caracteristicas {
  display: block;
  margin-top: 30px;
}
.producto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
@media (min-width: 50em) {
  .producto {
    flex-direction: row;
    text-align: left;
  }
}
.producto .mitad {
  max-width: 100%;
  flex-basis: auto;
}
@media (min-width: 50em) {
  .producto .mitad {
    max-width: 50%;
    flex-basis: 50%;
  }
}
@media (min-width: 50em) {
  .producto .reverse {
    order: -1;
  }
}
.producto h3 {
  font-family: 'Lora', serif;
  font-weight: 700;
  font-size: 30px;
  line-height: 1.833333333333333;
  letter-spacing: -1.31px;
}
@media (min-width: 64em) {
  .producto h3 {
    font-size: 52px;
    letter-spacing: -2.27px;
  }
}
.producto p {
  font-size: 18px;
  line-height: 2;
  color: #656b6f;
}
@media (min-width: 64em) {
  .producto p {
    font-size: 30px;
    margin-bottom: 20px;
  }
}
.producto a {
  display: block;
}
@media (min-width: 50em) {
  .producto a {
    display: inline-block;
    margin: 10px;
  }
}
.productos {
  padding: 50px 0;
}
@media (min-width: 64em) {
  .productos {
    padding: 100px 0;
  }
}
.header-receta {
  margin-top: 40px;
  padding: 30px 0 10px;
  text-align: center;
}
@media (min-width: 64em) {
  .header-receta {
    margin-top: 40px;
    padding: 50px 0;
  }
}
.header-receta h1 {
  font-family: 'Lora', serif;
  font-weight: 400;
  font-size: 38px;
}
@media (min-width: 64em) {
  .header-receta h1 {
    font-size: 64px;
    margin-bottom: 30px;
  }
}
.header-receta img {
  display: block;
  margin: 0 auto;
}
.receta {
  padding-bottom: 40px;
}
.receta .detalles {
  display: flex;
  align-items: center;
  color: #787878;
  padding: 10px 0;
}
.receta .detalles .clock,
.receta .detalles .user {
  width: 35px;
  height: 35px;
  margin: 0;
  margin-right: 10px;
  display: inline-block;
}
.receta .detalles span {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.receta .informacion {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  padding: 20px 0;
}
@media (min-width: 50em) {
  .receta .informacion {
    flex-direction: row;
  }
}
.receta .informacion .mitad {
  max-width: 100%;
  flex-basis: auto;
}
@media (min-width: 50em) {
  .receta .informacion .mitad {
    max-width: 50%;
    flex-basis: 50%;
  }
}
.receta .informacion h2 {
  font-family: 'Lora', serif;
  font-weight: 700;
  font-size: 28px;
  margin-bottom: 20px;
}
.receta .informacion ul,
.receta .informacion ol {
  margin: 20px 0;
  padding: 0 20px;
}
.receta .informacion ul {
  list-style-type: disc;
}
.receta .informacion ol {
  list-style: decimal;
}
.receta .informacion ol li {
  margin-bottom: 10px;
}
.receta .socialidad {
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (min-width: 50em) {
  .receta .socialidad {
    justify-content: flex-start;
  }
}
.receta .socialidad .download,
.receta .socialidad .facebook {
  width: 35px;
  height: 35px;
  margin: 10px;
  color: #787878;
}
.recetas {
  padding: 30px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
}
@media (min-width: 50em) {
  .recetas {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
}
.recetas .receta {
  max-width: 100%;
  flex-basis: auto;
  text-align: center;
  font-size: 20px;
  color: #000;
}
@media (min-width: 50em) {
  .recetas .receta {
    max-width: 33.33333333333333%;
    flex-basis: 33.33333333333333%;
    padding: 0 15px;
  }
}
.recetas .receta img,
.recetas .receta span {
  display: block;
}
.recetas .receta span {
  margin: 10px auto;
}
.contacto {
  *zoom: 1;
}
.contacto:before,
.contacto:after {
  content: '';
  display: table;
}
.contacto:after {
  clear: both;
}
@media (min-width: 50em) {
  .contacto {
    min-height: 100vh;
  }
}
.contacto__poster {
  content: '';
  width: 100%;
  height: 400px;
  background: linear-gradient(rgba(0,0,0,0.2), rgba(0,0,0,0.2)), url("../img/contacto.jpg") no-repeat center/cover;
}
@media (min-width: 50em) {
  .contacto__poster {
    float: left;
    position: fixed;
    top: 0;
    width: 50%;
    height: 100vh;
  }
}
.contacto__detalles {
  padding: 20px 0;
  text-align: center;
}
@media (min-width: 50em) {
  .contacto__detalles {
    float: left;
    width: 50%;
    margin-left: 50%;
  }
}
.contacto__detalles h1,
.contacto__detalles h4,
.contacto__detalles h6 {
  font-family: 'Lora', serif;
  font-weight: 400;
}
.contacto__detalles h4 {
  font-size: 21px;
}
@media (min-width: 64em) {
  .contacto__detalles h4 {
    font-size: 35px;
  }
}
.contacto__detalles h1 {
  font-size: 30px;
}
@media (min-width: 50em) {
  .contacto__detalles h1 {
    font-size: 50px;
  }
}
@media (min-width: 64em) {
  .contacto__detalles h1 {
    font-size: 72px;
  }
}
.contacto__detalles h6 {
  font-size: 18px;
}
@media (min-width: 64em) {
  .contacto__detalles h6 {
    font-size: 25px;
  }
}
.contacto__detalles hr {
  max-width: 100px;
  margin: 10px auto;
}
@media (min-width: 50em) {
  .contacto__detalles hr {
    max-width: 200px;
    margin: 20px auto;
  }
}
.contacto form {
  margin-bottom: 20px;
}
@media (min-width: 50em) {
  .contacto form {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}
.contacto input,
.contacto textarea {
  display: block;
  padding: 15px;
  background: #f4f4f4;
  border: 1px solid #a5a5a5;
  text-transform: uppercase;
  margin: 10px auto;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 400;
  font-size: 18px;
}
@media (min-width: 50em) {
  .contacto input,
  .contacto textarea {
    margin: 40px auto;
  }
}
.contacto input[type='submit'] {
  background: #000;
  color: #fff;
  max-width: 170px;
}
@media (min-width: 50em) {
  .contacto .flexyrow {
    justify-content: center;
  }
}
.contacto__social {
  color: #787878;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 3.74px;
  line-height: 1.285714285714286;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-bottom: 0.9375em;
}
@media (min-width: 50em) {
  .contacto__social {
    margin-bottom: 20px;
    margin-right: 15px;
    margin-left: 15px;
    font-size: 16px;
    letter-spacing: 3.69px;
    line-height: 1.125;
  }
}
.contacto__social .facebook {
  width: 15px;
  height: 15px;
  margin-right: 0.625em;
}
@media (min-width: 50em) {
  .contacto__social .facebook {
    width: 20px;
    height: 20px;
  }
}
.contacto__social .instagram {
  margin-right: 0.625em;
  width: 15px;
  height: 15px;
}
@media (min-width: 50em) {
  .contacto__social .instagram {
    width: 20px;
    height: 20px;
  }
}
.contacto__info {
  color: #4a4a4a;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 2.16px;
  line-height: 1.285714285714286;
  margin-bottom: 0.9375em;
}
@media (min-width: 50em) {
  .contacto__info {
    margin-bottom: 0;
    margin-right: 15px;
    margin-left: 15px;
  }
}
